import * as React from "react";
import ZincanIcon from "../../assets/ZincanLogo.svg";

const IndexPage = () => {
  return (
    <main>
      <div className="mt-16 flex justify-center">
        <ZincanIcon />
      </div>
      <footer className="flex justify-center">
        © {new Date().getFullYear()} &middot;
        {` `}
        <a href="https://www.zincan.com">Zincan</a>
      </footer>
    </main>
  );
};

export default IndexPage;

export const Head = () => <title>Zincan Home</title>;
